import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Importa guards
import { AuthGuard } from './providers/auth.guard';

// Define rutas y carga módulos lazy load
const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(module => module.LoginModule),
  },
  {
    path: 'analisis',
    canActivate: [AuthGuard],
    loadChildren: () => import('./analisis/analisis.module').then(module => module.AnalisisModule),
  },
  {
    path: '',
    redirectTo: '/analisis',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
