import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

// Importa clases e interfaces
import { ILogin } from '../classes/ilogin';
import { IRespuestaLogin } from '../classes/icredencial';
import { ICredencial } from '../classes/icredencial';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenUsuario: string;

  constructor(private httpClient: HttpClient) {
    this.leerToken();
  }

  login(login: ILogin) {
    const credenciales = {
      aplicacionId: environment.applicationId,
      ambienteId: environment.environmentId,
      ...login,
    };

    return this.httpClient.post<IRespuestaLogin>(`${environment.apiAuthUrl}/login-usuario`, credenciales).pipe(
      map(respuesta => {
        this.guardarToken(respuesta.token);
        this.guardarCredencial(respuesta.credencial);
        return true;
      })
    );
  }

  private guardarToken(token: string): void {
    this.tokenUsuario = token;
    localStorage.setItem('sarcan-auth', token);
  }

  private guardarCredencial(credencial: ICredencial): void {
    localStorage.setItem('sarcan-cred', JSON.stringify(credencial));
  }

  private leerToken(): string {
    if (localStorage.getItem('sarcan-auth')) {
      this.tokenUsuario = localStorage.getItem('sarcan-auth');
    } else {
      this.tokenUsuario = '';
    }

    return this.tokenUsuario;
  }

  public estaAutenticado(): boolean {
    // TODO: Generar servicio en la API para verificar el token valido
    return this.tokenUsuario.length > 2;
  }
}
